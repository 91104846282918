
.about-page {
    display: flex;
    flex-direction: column;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 2.5rem;
    font-size: 1.5rem;
    /* color: #23AFF7; */
   color: #0485C7; 
    width: 100%;
}

.about-page-content {
    margin: 6% 5%;
}

.about-h1 {
    margin-bottom: 4%;
    text-align: left;
}

.our-team-intro {
    display: flex;
    text-align: left;
}

.intro-text-container {
    width: 50%;
}
.about-illus-container {
    width: 50%;
}

.our-team-text p {
    text-align: left;
}

.about-page-content-two {
    background-color: #F2D0D2;
}

.team-members-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.member-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 2rem;
}

.title {
    text-transform: uppercase;
    margin-top: 0%;
}

h2 {
    color: #ED2233;
    margin-bottom: 0%;
}

.photo-container {
    width: 250px;
    height: 250px;
    
}

.member-photo {
    border-radius: 50%;

}

.ytlink {
    text-decoration: none;
    color: #23AFF7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ytlink:hover h2, .ytlink:hover p{
    /* color: #CEECFC; */
    color: #FFCF0C;
    color: white;

}

@media only screen and (max-width: 1035px) and (min-width: 350px){
    .our-team-intro {
        flex-direction: column;
    }

    .intro-text-container, .about-illus-container {
        width: 100%;
    }
     
}