
.homepage {
    display: flex;
    flex-direction: column;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 2.5rem;
}

.hero {
    display: flex;
    margin: 2rem 4rem;
}
.intro {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #23AFF7;
    font-size: 1.5rem;
    margin: 4rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-h1 {
    margin-bottom: 0%;
}

#illustration-container {
    max-width: 800px;
    height: auto;
    overflow: hidden;

}

#illustration {

}

button {
    color: white;
    padding: 1rem 2.5rem;
    border: none;
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 1rem;
}

.hero-para {
    text-align: center;
    
}

.herobutton {
    background-color: #FFCF0C;
    align-self: center;
}

.herobutton:hover {
    background-color: #f8ecbc;
    color: #FFCF0C;
}

.wawbutton {
    background-color: #CEECFC;
    color: #23AFF7;
}

.wawbutton:hover {
    background-color: #0485C7;
    color: #CEECFC;
}
.questionsbutton {
    background-color: #ED2233;
}

.questionsbutton:hover {
    background-color: #ee9ba0;
    color: #ED2233;
}

.designblock {
    background-color: #0485C7;
    width: 10%;
}

.whoarewe {
    display: flex;
}

.wawtext h1 {
font-size: 3.5rem;
margin: 0;
}
.wawtext {
    padding: 3.25rem 4rem;
    background-color: #23AFF7;
    color: white;
    font-size: 1.5rem;
}

.questions {
    background-color: #F2D0D2;
    display: flex;
    padding: 4rem;
    color: #ED2233;
}

.questions h1 {

    font-size: 3rem;
    margin: 0;
}

.questionsblock p {
    margin: 2rem 6rem;
    font-size: 1.5rem;
}

@media only screen and (max-width: 549px) and (min-width: 350px){


    .homepage {
        justify-content: center;
    }

    .homepage h1 {
        font-size: 2rem;
    }
    .hero {
        flex-direction: column;
        align-items: center;
        margin: 2rem 2rem;
    }

    .intro {
        margin: 4rem 0rem;
    }

    .wawtext {
        padding: 3.25rem 2rem;

    }

 

    .questions {
        flex-direction: column;
        padding: 4rem 2rem;
    }

   .questions h1{
        margin-top: 5rem !important;
    }
.questionsblock p {
    margin: 2rem 0rem;
}

.designblock {
    display: none;
}
}

@media only screen and (max-width: 1124px) and (min-width: 550px){

    .hero {
        flex-direction: column;
        align-items: center;
    }

 

    .questions {
        flex-direction: column;
    }

   .questions h1{
        margin-top: 5rem !important;
    }
    .questionsblock p {
        margin: 2rem 0rem;
    }

}

@media only screen and (max-width: 1300px) and (min-width: 1125px) {
    #illustration-container {
        max-width: 600px;
    }
   
    .hero-h1 {
        font-size: 2.5rem;
    }

    .intro {
        font-size: 1.25rem;
        line-height: 2rem;
    }

}