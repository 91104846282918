.get-help-page {
    display: flex;
    flex-direction: column;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 2.5rem;
    font-size: 1.5rem;
    color: white;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0%;
    padding: 0%;
}

.get-help-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* overflow: hidden; */
}
.get-help-title {
   margin-bottom: 2rem;
}

.get-help-first-half {
    padding: 5% 10%;
    background-color: #23AFF7;
    text-align: left;
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    overflow: none;
    
}

.firsthalf-content {
    display: flex;
    flex-direction: column;
    margin: 0% 5%;
    /* justify-content: center;
    align-content: center;
    align-items: center; */

}

.help-intro {
    display: flex;
}

.help-illus-container {    
    width: 40%;
}

.get-help-intro-text{
    width: 50%;
    margin-right: 10%;
    margin-top: 2%;
   
}

.get-help-second-half {
    background-color: #F2D0D2;
    color: #ED2233;
    padding: 5% 10%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
   
}

.get-help-form {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 0% 5%;
    width: 100%;
    flex-direction: column;
}

label {
    font-size: 1.45rem;
    font-weight: bold;
}

input, textarea {
    border: dotted 2px #23AFF7;
    width: 100%;
    
}

label, input {
    margin-right: 3rem;
}

.apply {
    background-color: #ED2233;
    color: white;
    width: 20rem;
    text-transform: uppercase;
    font-size: 3rem;
    justify-self: center;
    align-self: center;
    margin-top: 2rem;
}

.apply:hover {
    background-color: #ee9ba0;
    color: #ED2233;
}

.helpform-row {
display: flex;
width: 100%;
margin: 1.5rem 0%;
justify-content: center;

}

form .helpform {
    margin: 10% 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    overflow: hidden;
    flex-wrap: wrap;
   
}

.gethelp-ta {
    margin: 3% 0%;
}

.input-gethelp {
    height: 2rem;
}

.emp-name, .street-address {
    /* width: 60%; */
   flex-grow: 1;
}

input.checkbox {
    border: dotted 2px blue;
    height: 2rem;
    width: 2rem;
}

@media only screen and (max-width: 1030px) and (min-width: 350px) {

    .help-intro {
        flex-direction: column;
    }

    .get-help-title{
        /* align-self: center; */
        align-self: flex-start;
    }

    .helpform-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0%;
        padding: 0%;
    
    }

    label, input {
        margin-right: 0;
    }

    .help-illus-container {    
        width: 100%;
    }
    
    .get-help-intro-text{
        width: 100%;
       
    } 
form {
    font-size: 1rem;
}

#checkbox {
    flex-direction: row;
    justify-content: space-between;
}

.firsthalf-content{
    justify-content: center;
    align-items: center;
    align-items: center;
}

.apply {
    margin-bottom: 15%;
}

.input-gethelp, .street-address {
    margin-left: 0%;
}
    
}