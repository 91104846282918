.success {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 2.5rem;
    font-size: 1.5rem;
    background-color: #23AFF7;
    color: white;
}

.success-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5% 5%;
}

.success-h1 {
    font-size: 4rem;
    line-height: 4rem;
    
}

.success-illus-container {
    width: 50%;
}

.thankyou {
    font-size: 2rem;
    color: #FFCF0C;

}

.success-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0% 10%;
}