.donate {
    color: white;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    padding: 2% 5%;
    line-height: 2.5rem;
    width: 100%;
    margin: 0%;
    padding: 0%;
    overflow: hidden;
}

.content {
    /* padding: 1% 5%; */
    width: 100%;
    background-color: #23AFF7;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.why {
    margin: 5% 5%;
    display: flex;
    flex-direction: column;
    padding: 3% 0%;
}

.donate-h1 {
    align-self: flex-start;
    margin: 0%;
}

.support-sptv-h1 {
    align-self: flex-start;
    margin: 0%;
}

.first-row {
    display: flex;
    margin-top: 4%;
}

.second-row {
    display: flex;
    margin-top: 5%;
}

.image-container {
    max-width: 35%;
    display: flex;
    padding: 0%;
    height: auto;
    align-items: center;
}

img {
    max-width: 100%;
    height: auto;
    padding: 0%;
}

.paragraph {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 65%;
    margin-right: 2%;
}

.donate-para {
    text-align: left;
}

.second-para-why {
    margin-left: 5%;
}

.support {
    background-color: #F2D0D2;
    color: #ED2233;
    padding: 8% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.support-content {
    display: flex;
    flex-direction: column;
    margin: 0% 5%;
}

.support-intro {
    display: flex;
    /* margin: 5% 5%; */
}

.support-intro-top {
    margin-top: 5%;
}

.third-img-container {
    width: 45%;
}

.first-para-support {
    width: 50%;
    /* margin-left: 5%; */
    margin: 2% 0% 0% 5%;
}

.donate-box-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.donate-box {
    background-color: #CEECFC;
    width: 75%;
    align-self: center;
    border-radius: 50px;
    color: #0485C7;
    padding: 0% 0% 5% 0%;
    margin: 5% 0%;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0% 5%;
}

.first-row-db,
.second-row-db,
.third-row-db {
    display: flex;
    justify-content: space-around;
    /* align-items: space-between; */
}

.donate-button {
    background-color: #23AFF7;
    font-size: 3rem;
    margin-top: 4rem;
    align-self: center;
    display: flex;
    justify-content: center;
}

.donate-button:hover {
    background-color: #FFCF0C;
}

.checkbox {
    display: flex;
    justify-content: space-around;
}

input.check {
    border: dotted 2px blue;
    height: 2rem;
    width: 2rem;
}

select,
input,
textarea {
    border: none;
}

select,
input {
    margin-top: 2px;
    margin-left: 1rem;
    height: 3rem;
    padding: 0rem 1rem;
}

label {
    font-size: 2rem;
}

#donoSelect {
    font-size: 2rem;
    margin: 3% auto;
}

.onetime,
.recurring-donation {
    display: flex;
    flex-direction: column;
}

.paypal {
    font-size: .85rem;
    margin-top: 5%;
    font-style: italic;
    /* font-weight: bold; */
    color: #0485C7;

}

.donate-now {
    margin-top: 5%;
    background-color: #FFCF0C;
    color: white;
    text-decoration: none;
    padding: 1rem 2.5rem;
    border: none;
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    align-self: center;

}

.donate-now:hover{

    background-color: #ED2233;
}

@media only screen and (max-width: 768px) and (min-width: 350px) {

    h1 {
        font-size: 2rem;
        align-self: center;
    }

    .why {
        justify-content: center;
        align-items: center;
    }

    .first-row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .second-row {
        flex-direction: column;
    }

    .paragraph {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 0%;
    }

    .image-container {
        max-width: 100%;
    }

    .donate-para {
        text-align: left;
        padding: 2% 5%;
        margin: 0%;
    }

    .support-intro {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .third-img-container {
        width: 100%;
    }

    .first-para-support {
        width: 100%;
    }

    .support-intro {
        padding: 8% 5%;
    }

    .donate-box-section {
        /* padding: 8% 5%; */
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .donate-box {
        display: flex;
        width: 100%;
        border-radius: 0%;
        justify-content: center;
        align-items: center;
    }

    .first-row-db,
    .second-row-db,
    .checkbox,
    .third-row-db {
        flex-direction: column;
    }

    .checkbox input {
        margin-bottom: 2rem;
    }

}

@media only screen and (max-width: 1200px) and (min-width: 769px) {

    h1 {

        align-self: center;
    }

    .why {
        justify-content: center;
        align-items: center;
    }

    .first-row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .second-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .paragraph {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 0%;
    }

    .image-container {
        max-width: 80%;
    }

    .donate-para {
        text-align: center;
        padding: 2% 5%;
        margin: 0%;
    }

    .support-intro {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .third-img-container {
        width: 100%;
    }

    .first-para-support {
        width: 100%;
    }

    .support-intro {
        padding: 8% 5%;
    }

    .donate-box-section {
        /* padding: 8% 5%; */
        justify-content: center;
        align-items: center;
        width: 100%;

    }

    .donate-box {
        display: flex;
        width: 100%;
        border-radius: 0%;
        justify-content: center;
        align-items: center;
    }

    .donate-button {
        margin-right: 1rem;

    }

    #donate {
        width: 20rem;
    }



    /*
    .first-row-db, .second-row-db, .checkbox{
        flex-direction: column;
    }

    .checkbox input{
        margin-bottom: 2rem;
    } */

}