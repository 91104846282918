.header {
    background-color: #CEECFC;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 8%;
    overflow: hidden;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: white;
    
}

.header-contact {
    display: none;
}

#logo {
    height: 100px;
}

.navigation {
    display: flex;
    align-items: flex-end;
    overflow: hidden;
}

.navigation ul {
    display: flex;

}

.nav-item {
    list-style: none;
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #0485C7;
    margin-right: 4rem;
    font-size: 1.5rem;
}

.nav-link {
    text-decoration: none;
    color: #0485C7;
}

.nav-item .nav-link:hover {
    color: #ee9ba0;
    /* color: #f8ecbc; */
}

.alt-nav {
    display: none;
    /* position: fixed; */
}



/* //////////////////////////////////////////////////////Footer  */
footer {
    background-color: #ED2233;
    color: white;
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.5rem;
    padding: 1rem 1rem;
    display: flex;
    /* width: 100%; */
    justify-content: space-around;
    align-items: center;
    
}

.contact {
    display: flex;
    flex-direction: column;
}

.contact-container {
    display: flex;
    align-items: center;
}

.icon-container {
    margin-right: 1rem;
}

.phoneemail {
    color: white;
    text-decoration: none;

}

.phoneemail:hover {
    /* color: #ee9ba0; */
    color: #FFCF0C;

}



/* //media queries */

@media only screen and (max-width: 550px) and (min-width: 350px) {

    .navigation {
        display: none;
    }

    .header {
        justify-content: center;
        align-items: center;
        padding: 1.5rem 0% !important;
        display: flex;
        flex-direction: column;

    }

    .footer-para {
        display: none;
    }

    .alt-nav {
        display: flex;
        width: 100%;
        padding: 0%;
        margin: 0%;
        justify-content: center;
    }

    .alt-nav ul {
        display: flex;
        margin: 0%;
        justify-content: center;
    }

    .nav-item {
        margin-right: 1rem;
        font-size: 1.1rem;
    }

    .nav-item a {
        color: white;
    }

    .footer {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0%;
        box-sizing: border-box;
        position: fixed;
        bottom: 0;
    }

    .contact {
        display: none;
    }

    #logo {
        margin-bottom: 5%;
    }

    .header-contact {
        display: flex; 
        flex-direction: column;
        justify-content: space-around;
        background-color: #0485C7;   
        align-items: space-around;
        width: 100%; 
    }

    .header-contact-container {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1rem;
        padding-left: 2rem;
        width: 100%;
        
    }

    .header-icon-container {
        margin-right: 1rem;;
    }

}

@media only screen and (max-width: 930px) and (min-width: 551px) {

    .navigation {
        display: none;
    }

    #logo {
        margin-bottom: 4%;
    }

    .header-contact {
        display: flex; 
        justify-content: space-around;
        background-color: #0485C7;   
        align-items: space-around;
        

    }

    .header-contact-container {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1.25rem;
        
    }

    .header-icon-container {
        margin-right: 1rem;;
    }

    

    .header {
        justify-content: center;
        width: 100%;
        padding: 1.5rem 0%;
        display: flex;
        flex-direction: column;
    }

    .footer-para {
        display: none;
    }

    footer {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0%;
        box-sizing: border-box;
    }

    .alt-nav {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 0%;
        margin: 0%;
    }

    .alt-nav ul {
        display: flex;
        margin: 0%;
        width: 100%;
        padding: 0%;
        justify-content: center;
        align-items: center;
    }

    .nav-item {

        margin-right: 1rem;
        font-size: 1.5rem;
    }

    .nav-item a {
        color: white;
    }

    .contact {
        display: none;
    }


}

@media only screen and (max-width: 1060px) and (min-width: 931px) {

    footer {
        font-size: 1.25rem;
    }

}